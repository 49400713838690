import * as consts from 'components/views/FounderDealShared/_helpers/founderDealShared.consts';
import * as events from 'components/views/FounderDealShared/_helpers/founderDealShared.events';
import * as resolvers from 'components/views/FounderDealShared/_helpers/founderDealShared.resolvers';

// Constants exports
export const { $founderDealShared, $founderDealSharedUI, TABS, RATINGS } = consts;

// Event handlers exports
export const {
  handleFormChange,
  clearAndCloseModal,
  handleNavigateToLogin,
  addDealToPipeline,
} = events;

// Resolvers/Utils exports
export const {
  fetchAndSetData,
  determineRedirect,
  getSnapshotRows,
  getCompanyRows,
  AddButtonPopOver,
} = resolvers;
