// Import and re-export all helpers from the _helpers directory
import {
  fetcher,
  $verifyEmail,
} from './_helpers/verifyEmail.consts';

import {
  handleVerification,
} from './_helpers/verifyEmail.events';

// Re-export constants
export {
  fetcher,
  $verifyEmail,
};

// Re-export event handlers
export {
  handleVerification,
};

// Default export for backward compatibility
export default handleVerification;
