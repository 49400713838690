import Signal from 'libs/signals';

export const $founderDealShared = Signal({});

export const $founderDealSharedUI = Signal({
  isModalVisible: false,
  tab: 'Deal Snapshot',
});

export const TABS = ['Deal Snapshot', 'Company Info', 'File Room'];

export const RATINGS = ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-'];
