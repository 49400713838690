import Fetcher from 'libs/fetcher';
import $user from 'signals/User.signal';
import displayError from 'utils/displayError';
import { getBaseTodos } from '../../../../constants';
import { $founderDealShared, $founderDealSharedUI } from './founderDealShared.consts';

const fetcher = new Fetcher();

export const handleFormChange = ({ name, value }) => $founderDealShared.update({ [name]: value });

export const clearAndCloseModal = () => {
  $founderDealSharedUI.update({
    isModalVisible: false,
  });

  $founderDealShared.update({
    teamId: undefined,
    rating: undefined,
  });
};

export const handleNavigateToLogin = (path, history) => {
  $founderDealShared.update({ path });
  history.push('/login');
};

export const addDealToPipeline = async (history) => {
  $founderDealSharedUI.update({ isModalLoading: true });
  const { id, teamId, rating, status } = $founderDealShared.value;
  const { organization: userOrg, id: userId } = $user.value;

  try {
    await Promise.all([
      fetcher.post({
        path: '/investorsonfounderpipeline',
        body: {
          data: {
            investor_org: { connect: { id: userOrg?.investor_org_id } },
            deal: { connect: { id } },
            pipeline_status: status,
            todos: getBaseTodos(),
          },
        },
      }),
      fetcher.post({
        path: `/dealsoninvestorpipeline/${userId}`,
        body: {
          data: {
            investor_org_id: userOrg?.investor_org_id,
            deal_id: id,
            pipeline_status: 'new',
            rating,
            team_id: +teamId || null,
          },
        },
      }),
    ]);

    history.push('/investor/pipeline');
  } catch (e) {
    displayError(e);
  } finally {
    $founderDealSharedUI.update({ isModalLoading: false });
  }
};
